import React, { FC } from "react";
import styled from "styled-components";
import { InternalLink } from "../../link/InternalLink";
import { ExternalLink } from "../../link/ExternalLink";
import { Device } from "../../../helpers/screenSizes";
import { FooterNavigationLinkProps } from "../types";

const StyledFooterNavigationLinks = styled.div`
  min-width: 120px;
  font-size: 1rem;

  @media ${Device.mobile} {
    min-width: 100%;
  }

  @media ${Device.tablet} {
    min-width: 50%;
  }

  @media ${Device.laptop} {
    display: flex;
    justify-content: flex-end;
  }
`;

const CategoryName = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.25rem;
`;

const NavigationList = styled.div`
  display: flex;
  flex-direction: column;
  /* We need it so we can have line breaks with \n */
  white-space: pre;

  > :not(:first-child) {
    padding-top: 20px;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  font-size: 1rem;

  @media ${Device.mobile} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledInternalLink = styled(InternalLink)`
  font-size: 1rem;

  @media ${Device.mobile} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FooterNavigationLinks: FC<FooterNavigationLinkProps> = ({
  categoryName,
  links,
  onClickHandler,
}) => {
  return (
    <StyledFooterNavigationLinks>
      <CategoryName>{!!categoryName && categoryName}</CategoryName>
      <NavigationList>
        {!!links &&
          links.map(({ name, url, isOpenNewTab, isInternal }, index) =>
            isInternal ? (
              <StyledInternalLink href={url} key={index}>
                {name}
              </StyledInternalLink>
            ) : (
              <StyledExternalLink
                key={index}
                href={url}
                openInSameTab={!isOpenNewTab}
                onClick={onClickHandler}
              >
                {name}
              </StyledExternalLink>
            )
          )}
        {/* empty div is intentional. ccm script inserts here based on id. */}
        <div id="teconsent" />
      </NavigationList>
    </StyledFooterNavigationLinks>
  );
};
