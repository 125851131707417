import React from "react";
import styled, { css } from "styled-components";
import { redirectKeyPressToClick } from "../common/helpers";
import { HeaderLogoProps } from "../types/header";
import { Device } from "../../../../helpers/screenSizes";

interface ImgProps {
  leftAlignOnMobile?: boolean;
  customWidthOnMobile?: number;
}

interface LogoProps {
  leftAlignOnMobile?: boolean;
  customWidthOnMobile?: number;
}

const Img = styled.img<ImgProps>`
  height: 50px;
  width: 160px;
  object-fit: contain;
  object-position: left;

  ${({ customWidthOnMobile }) =>
    customWidthOnMobile &&
    css`
      @media ${Device.mobile} {
        width: ${customWidthOnMobile}px;
      }
    `};

  &.clickable {
    cursor: pointer;
  }
`;

const Logo = styled.div<LogoProps>`
  display: flex;
  align-items: center;
  height: inherit;

  ${({ leftAlignOnMobile }) =>
    !leftAlignOnMobile &&
    css`
      @media ${Device.mobile} {
        /* center the logo https://stackoverflow.com/a/25776315 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `};
`;

const Anchor = styled.a`
  display: flex;
  align-items: center;
`;

export const HeaderLogo: React.FunctionComponent<HeaderLogoProps> = ({
  logoImgLink,
  onClickHandler,
  logoUrl,
  customWidthOnMobile,
  leftAlignOnMobile,
}) => {
  if (logoUrl && !onClickHandler) {
    return (
      <Logo
        customWidthOnMobile={customWidthOnMobile}
        leftAlignOnMobile={leftAlignOnMobile}
      >
        <Anchor tabIndex={0} href={logoUrl}>
          <Img
            src={logoImgLink}
            alt="Brand logo"
            customWidthOnMobile={customWidthOnMobile}
            leftAlignOnMobile={leftAlignOnMobile}
          />
        </Anchor>
      </Logo>
    );
  } else if (!logoUrl && onClickHandler) {
    return (
      <Logo
        customWidthOnMobile={customWidthOnMobile}
        leftAlignOnMobile={leftAlignOnMobile}
      >
        <Img
          tabIndex={0}
          src={logoImgLink}
          onClick={onClickHandler}
          onKeyDown={redirectKeyPressToClick}
          className="clickable"
          alt="Brand logo"
          customWidthOnMobile={customWidthOnMobile}
          leftAlignOnMobile={leftAlignOnMobile}
        />
      </Logo>
    );
  } else if (!logoUrl && !onClickHandler) {
    // no interaction on the logo; just display the image
    return (
      <Logo
        customWidthOnMobile={customWidthOnMobile}
        leftAlignOnMobile={leftAlignOnMobile}
      >
        <Img
          src={logoImgLink}
          alt="Brand logo"
          customWidthOnMobile={customWidthOnMobile}
          leftAlignOnMobile={leftAlignOnMobile}
        />
      </Logo>
    );
  } else {
    // both logoUrl and onClickHandler provided
    return (
      <Logo
        onClick={onClickHandler}
        customWidthOnMobile={customWidthOnMobile}
        leftAlignOnMobile={leftAlignOnMobile}
      >
        <Anchor href={logoUrl}>
          <Img
            src={logoImgLink}
            alt="Brand logo"
            customWidthOnMobile={customWidthOnMobile}
            leftAlignOnMobile={leftAlignOnMobile}
          />
        </Anchor>
      </Logo>
    );
  }
};
