import { useEffect, useState } from "react";

const MOBILE_BREAKPOINT = 768;

/**
 * Simple hook to detect if device is mobile, based on
 * inner width. If we need more complicated width logic,
 * consider writing other hook.
 */
export function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const isClient = typeof window === "object";
    if (!isClient) {
      return;
    }

    function handleResize() {
      if (isClient) {
        setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
      } else {
        setIsMobile(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isMobile;
}
