import React from "react";

export const HeaderIconSvg = {
  location: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.353"
      height="29.592"
      viewBox="0 0 20.353 29.592"
      // IE11 tab focus fix https://stackoverflow.com/a/39574310
      focusable="false"
    >
      <g id="Group_111" transform="translate(-255.33 -114.861)">
        <path
          id="Path_167"
          d="M265.506 144.453a1 1 0 0 1-.758-.349c-.384-.446-9.418-11.041-9.418-19.066a10.177 10.177 0 1 1 20.353 0c0 8.025-9.034 18.62-9.418 19.067a1 1 0 0 1-.759.348zm0-27.592a8.186 8.186 0 0 0-8.176 8.177c0 6.007 6.018 14.119 8.176 16.835 2.159-2.716 8.177-10.828 8.177-16.835a8.186 8.186 0 0 0-8.177-8.177z"
          className="cls-1"
        />
        <path
          id="Path_168"
          d="M265.506 130.25a4.774 4.774 0 1 1 4.775-4.774 4.779 4.779 0 0 1-4.775 4.774zm0-7.548a2.774 2.774 0 1 0 2.775 2.774 2.777 2.777 0 0 0-2.775-2.776z"
          className="cls-1"
        />
      </g>
    </svg>
  ),
  search: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__search-icon"
      width="20.112"
      height="25"
      viewBox="0 0 20.112 25"
    >
      <path
        id="prefix__Path_27"
        d="M41.428 131.636a8.489 8.489 0 1 1 8.49-8.49 8.5 8.5 0 0 1-8.49 8.49zm0-15.31a6.821 6.821 0 1 0 6.821 6.821 6.829 6.829 0 0 0-6.821-6.822z"
        className="prefix__cls-1"
        data-name="Path 27"
        transform="translate(-32.938 -114.657)"
      />
      <path
        id="prefix__Path_28"
        d="M55.156 143.292a.831.831 0 0 1-.676-.345l-3.651-5.038a.834.834 0 1 1 1.351-.979l3.651 5.038a.834.834 0 0 1-.675 1.323z"
        className="prefix__cls-1"
        data-name="Path 28"
        transform="translate(-35.878 -118.292)"
      />
    </svg>
  ),
  user: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__account-icon"
      width="17.787"
      height="25"
      viewBox="0 0 17.787 25"
      // IE11 tab focus fix https://stackoverflow.com/a/39574310
      focusable="false"
    >
      <path
        id="prefix__Path_25"
        d="M79.64 123.442a4.393 4.393 0 1 1 4.392-4.393 4.4 4.4 0 0 1-4.392 4.393zm0-7.117a2.724 2.724 0 1 0 2.724 2.724 2.727 2.727 0 0 0-2.724-2.724z"
        className="prefix__cls-1"
        data-name="Path 25"
        transform="translate(-70.747 -114.657)"
      />
      <path
        id="prefix__Path_26"
        d="M86.805 141.978a.834.834 0 0 1-.834-.834c0-5.965-3.241-10.818-7.225-10.818s-7.225 4.854-7.225 10.818a.834.834 0 1 1-1.668 0c0-7 3.907-12.487 8.894-12.487s8.894 5.485 8.894 12.487a.834.834 0 0 1-.836.834z"
        className="prefix__cls-1"
        data-name="Path 26"
        transform="translate(-69.852 -116.978)"
      />
    </svg>
  ),
  shoppingCart: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__cart-icon"
      width="21.556"
      height="25"
      viewBox="0 0 21.556 25"
      // IE11 tab focus fix https://stackoverflow.com/a/39574310
      focusable="false"
    >
      <path
        id="prefix__Path_23"
        d="M116.328 141.365H102.38a.835.835 0 0 1-.818-.669l-2.969-14.737a.834.834 0 0 1 .818-1H119.3a.835.835 0 0 1 .818 1l-2.97 14.737a.833.833 0 0 1-.82.669zm-13.265-1.665h12.583l2.633-13.068H100.43z"
        data-name="Path 23"
        transform="translate(-98.576 -116.365)"
      />
      <path
        id="prefix__Path_24"
        d="M115.694 120.449a.834.834 0 0 1-.765-.5c-.974-2.235-2.782-3.623-4.718-3.623s-3.743 1.388-4.718 3.623a.834.834 0 0 1-1.529-.667c1.244-2.852 3.637-4.624 6.247-4.624s5 1.772 6.247 4.624a.835.835 0 0 1-.764 1.168z"
        data-name="Path 24"
        transform="translate(-99.458 -114.657)"
      />
    </svg>
  ),
  arrow: (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="30.727px"
      height="30.727px"
      viewBox="0 0 30.727 30.727"
    >
      <g>
        <path d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z" />
      </g>
    </svg>
  ),
};
