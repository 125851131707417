import { endpoints } from "config";
import {
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
  NormalizedCacheObject,
  DefaultOptions,
} from "@apollo/client";
import { IncomingMessage } from "http";
import { errorLink } from "./client";

const createSsrClient = (
  req?: IncomingMessage,
  noCookies?: boolean
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = new HttpLink({
    uri: `${endpoints.internalApi}/graphql`,
    credentials: "include",
    // Here, we are initialising fetch with cookies in the server side. If we don't,
    // then Next.js does not bind cookies coming from the client in the upcoming request.
    fetch: (url, init) =>
      fetch(url, {
        ...init,
        headers: {
          ...init?.headers,
          "pepdirect-override-tenant": endpoints.api,
          ...(req?.headers?.cookie &&
            !noCookies && { Cookie: req?.headers?.cookie }),
        },
      }).then((response) => response),
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache" as const,
    },
    query: {
      fetchPolicy: "no-cache" as const,
    },
    mutate: {
      fetchPolicy: "no-cache" as const,
    },
  };

  const link = from([errorLink, httpLink]);

  // Here, instead of using direct string URI, we use the link object that has
  // just been initalised above.
  const client = new ApolloClient({
    credentials: "include",
    defaultOptions,
    cache: new InMemoryCache(),
    link,
  });
  return client;
};

export default createSsrClient;
