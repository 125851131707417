import React from "react";
import styled from "styled-components";
import { AlertType } from "./Alert";
import { colors } from "../../styles/variables";

const StyledSvg = styled.svg<ExclamationIconSvgProps>`
  path,
  circle {
    fill: ${({ type, theme }) =>
      type === "error" ? theme.color.error : colors.darkGray};
  }
`;

interface ExclamationIconSvgProps {
  type: AlertType;
}

export const ExclamationIconSvg = ({
  type = "info",
}: ExclamationIconSvgProps) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="20.353"
    height="20.353"
    viewBox="0 0 20.353 20.353"
    type={type}
  >
    <g
      id="prefix__Group_81"
      data-name="Group 81"
      transform="translate(-227.354 -119.48)"
    >
      <path
        id="prefix__Path_161"
        d="M237.53 139.833a10.176 10.176 0 1 1 10.177-10.176 10.187 10.187 0 0 1-10.177 10.176zm0-18.353a8.176 8.176 0 1 0 8.177 8.177 8.185 8.185 0 0 0-8.177-8.177z"
        data-name="Path 161"
      />
      <path
        id="prefix__Path_162"
        d="M237.53 132.11a1 1 0 0 1-1-1v-6.537a1 1 0 1 1 2 0v6.537a1 1 0 0 1-1 1z"
        data-name="Path 162"
      />
      <circle
        id="prefix__Ellipse_2"
        cx="1"
        cy="1"
        r="1"
        data-name="Ellipse 2"
        transform="translate(236.53 133.768)"
      />
    </g>
  </StyledSvg>
);
