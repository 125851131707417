import {
  BrandConfigCustomizationsContact,
  BrandConfigCustomizationsImage,
  BrandConfigCustomizationsLink,
  FooterBranding,
  FooterLink,
} from "./types";

export const getSortedFooterLinks = (
  links: BrandConfigCustomizationsLink[],
  urlBase?: string
): FooterLink[] => {
  return links
    .filter((link) => link.location === "footer")
    .sort((a, b) => a.position - b.position)
    .map((link) => {
      const isInternal = urlBase ? link.url.includes(urlBase) : false;
      return {
        name: link.title,
        url: link.url,
        isOpenNewTab: link.newTab,
        isInternal,
      };
    });
};

export const getFooterBranding = (
  contacts: BrandConfigCustomizationsContact[],
  images: BrandConfigCustomizationsImage[]
): FooterBranding => {
  let logo, contact;

  if (images) {
    logo = images.find(
      (img: BrandConfigCustomizationsImage) => img.name === "logoLargeLightUrl"
    );
  }

  if (contacts) {
    contact = contacts.find(
      (contact: BrandConfigCustomizationsContact) => contact.type === "footer"
    );
  }

  return {
    logoImgLink: logo?.src || "",
    phoneNumber: contact?.phoneNumber || "",
    addressLine1: contact
      ? `${contact.line1}${contact.line2 ? `, ${contact.line2}` : ""}`
      : "",
    addressLine2: contact
      ? `${contact.city}, ${contact.state} ${contact.zip}`
      : "",
  };
};
