import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Device } from "../../../../helpers/screenSizes";
import { redirectKeyPressToClick } from "../common/helpers";
import { HeaderIconSvg } from "../common/icons";
import { HeaderUserProps } from "../types/header";

const StyledUser = styled.div`
  @media ${Device.mobile} {
    display: none;
  }

  .header-user__support {
    cursor: default !important;
    color: #000;
  }
  .header-user__support-link {
    font-size: 10px;
    color: ${({ theme }) => theme.color.primary};
  }
  .header-dropdown-header {
    color: ${({ theme }) => theme.color.primary};
  }
  .header-user__dropdown-menu {
    display: none;

    &--open {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 10001;
      position: absolute;
      font-size: 0.9rem;
      color: ${({ theme }) => theme.color.primary};
      background-color: #fff;
      min-width: 100px;
      margin-top: 16px;
      padding: 5px;

      ::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 9px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
      }

      & > * {
        width: 100%;
        text-align: center;
        cursor: pointer;
        padding: 5px 0;
      }
    }
  }
`;

const Label = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.header?.shop?.navLinkColor || "#fff"};
  font-size: 14px;
`;

const IconAndText = styled.span`
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;

  svg {
    width: 18px;

    path {
      fill: ${({ theme }) => theme.header?.shop?.navLinkColor || "#fff"};
    }
  }
`;

export const HeaderUser: React.FunctionComponent<HeaderUserProps> = ({
  isLoggedIn,
  onLogInClicked,
  onLogOutClicked,
  onAccountClicked,
  logInUrl,
  logOutUrl,
  accountUrl,
  shouldShow = true,
  showATDContact = false,
}: HeaderUserProps) => {
  const [isDropdownMenuOpen, setDropdownMenu] = useState("");
  const toggleDropdownMenu = (): void => {
    setDropdownMenu(isDropdownMenuOpen.length ? "" : "--open");
  };
  const closeMenu = (): void => setDropdownMenu("");

  const toggleNode = useRef<HTMLDivElement>(null);

  const closeDropdownMenu = (event: MouseEvent): void => {
    if (
      toggleNode &&
      toggleNode.current &&
      toggleNode.current.contains(event.target as Node)
    ) {
      // the user clicked inside the dropdown; do nothing
      return;
    }
    setDropdownMenu("");
  };

  useEffect(() => {
    if (isDropdownMenuOpen.length) {
      document.addEventListener("mousedown", closeDropdownMenu);
    } else {
      document.removeEventListener("mousedown", closeDropdownMenu);
    }

    return (): void => {
      document.removeEventListener("mousedown", closeDropdownMenu);
    };
  }, [isDropdownMenuOpen]);

  if (!shouldShow) {
    return null;
  }

  return isLoggedIn ? (
    <StyledUser className="header-user--logged-in" ref={toggleNode}>
      <IconAndText
        tabIndex={0}
        onKeyDown={redirectKeyPressToClick}
        onClick={(): void => toggleDropdownMenu()}
      >
        {HeaderIconSvg.user}
        <Label>Account</Label>
      </IconAndText>
      <div className={`header-user__dropdown-menu${isDropdownMenuOpen}`}>
        {showATDContact && (
          <div className="header-user__support">
            <div>Support:</div>
            <div
              className="header-user__support-link"
              style={{ fontSize: "14px" }}
            >
              ATDorders@pepsico.com
            </div>
          </div>
        )}
        {accountUrl ? (
          <a href={accountUrl} tabIndex={0} className="header-dropdown-header">
            Account
          </a>
        ) : onAccountClicked ? (
          <div
            onClick={(event: React.MouseEvent): void => {
              closeMenu();
              onAccountClicked && onAccountClicked(event);
            }}
            onKeyDown={redirectKeyPressToClick}
            tabIndex={0}
            className=".header-dropdown-header"
          >
            Account
          </div>
        ) : null}
        {logOutUrl ? (
          <a href={logOutUrl} tabIndex={0}>
            Log Out
          </a>
        ) : onLogOutClicked ? (
          <div
            onClick={(event: React.MouseEvent): void => {
              closeMenu();
              onLogOutClicked && onLogOutClicked(event);
            }}
            onKeyDown={redirectKeyPressToClick}
            tabIndex={0}
          >
            Log Out
          </div>
        ) : null}
      </div>
    </StyledUser>
  ) : (
    <StyledUser className="header-user--logged-out">
      {logInUrl ? (
        <a href={logInUrl} tabIndex={0}>
          <IconAndText>
            {HeaderIconSvg.user}
            <Label>Account</Label>
          </IconAndText>
        </a>
      ) : onLogInClicked ? (
        <div
          onClick={onLogInClicked}
          onKeyDown={redirectKeyPressToClick}
          tabIndex={0}
        >
          <IconAndText>
            {HeaderIconSvg.user}
            <Label>Login</Label>
          </IconAndText>
        </div>
      ) : null}
    </StyledUser>
  );
};
