import { colors } from "../styles/variables";
import {
  FontNames,
  FontWeights,
  TextTransform,
  HexColor,
} from "@pepdirect/shared/types";

type themeNameTypes =
  | "PantryShop"
  | "PepsiCoShop"
  | "TeamZone"
  | "SodaStream"
  | "SodaStreamPro"
  | "Gatorade"
  | "RockstarEnergy"
  | "RockstarEnergyShop"
  | "PepDefault"
  | "GatoradeMicrosite";

export const themeNames: Record<themeNameTypes, string> = {
  PantryShop: "PantryShop",
  PepsiCoShop: "PepsiCoShop",
  TeamZone: "Gatorade TeamZone",
  SodaStreamPro: "SodaStream Pro",
  Gatorade: "Gatorade",
  RockstarEnergy: "Rockstar Energy",
  RockstarEnergyShop: "Rockstar Energy Shop",
  SodaStream: "SodaStream",
  PepDefault: "Pep Default",
  GatoradeMicrosite: "Gatorade Microsite",
};

interface ButtonStyles {
  color: HexColor;
  textColor?: HexColor;
  hollow?: boolean;
  solidOnHover?: boolean;
  hollowOnHover?: boolean;
}

interface HeadingStyles {
  fontSize: {
    desktop: string;
    mobile?: string;
  };
  fontFamily?: FontNames;
  fontWeight?: FontWeights;
  letterSpacing?: string;
  lineHeight?: string;
  color?: HexColor;
  textTransform?: TextTransform;
}

export interface ThemeInterface {
  name: string;
  color: {
    primary: HexColor;
    secondary: HexColor;
    tertiary: HexColor;
    accent: HexColor;
    disabled: HexColor;
    action: HexColor;
    error: HexColor;
    text: HexColor;
    adaCompliantText?: HexColor;
  };
  button: {
    primary: ButtonStyles;
    secondary: ButtonStyles;
    tertiary: ButtonStyles;
    cancel: ButtonStyles;
    error: ButtonStyles;
    text: {
      color: HexColor;
    };
    disabled?: {
      hollow?: boolean;
    };
    fontFamily?: FontNames;
    fontWeight?: FontWeights;
    textTransform?: string;
    hasThinBorder?: boolean;
    borderRadius?: string;
    padding?: string;
  };
  typography: {
    body?: {
      fontSize?: string;
      fontFamily?: FontNames;
      fontWeight?: FontWeights;
      letterSpacing?: string;
      lineHeight?: string;
      color?: HexColor;
    };
    heading: {
      xxl: HeadingStyles;
      xl: HeadingStyles;
      l: HeadingStyles;
      m: HeadingStyles;
      s: HeadingStyles;
      xs: HeadingStyles;
      xxs: HeadingStyles;
    };
  };
  borderRadius?: string;
  page?: {
    backgroundColor?: HexColor;
  };
  header?: {
    fontFamily?: FontNames;
    fontSize?: string;
    fontWeight?: FontWeights;
    textTransform?: TextTransform;
    portal?: {
      backgroundColor?: HexColor;
      mobileLinkColor?: HexColor;
      navLinkColor?: HexColor;
      navLinkOpacity?: string;
      fontWeight?: FontWeights;
      textTransform?: string;
      fontSize?: string;
      borderBottomColor?: HexColor;
    };
    shop?: {
      backgroundColor?: HexColor;
      mobileLinkColor?: HexColor;
      navLinkColor?: HexColor;
    };
    simple?: {
      checkout?: {
        backgroundColor?: HexColor;
      };
      portal?: {
        backgroundColor?: HexColor;
      };
    };
  };
  footer?: {
    backgroundColor?: HexColor;
    textColor?: HexColor;
    copyright?: {
      backgroundColor?: HexColor;
      textColor?: HexColor;
    };
  };
  alert?: {
    info?: {
      textColor?: HexColor;
    };
  };
  priceAccentColor?: HexColor;
  dividerColor?: HexColor;
  banner?: {
    backgroundColor?: HexColor;
  };
  cart?: {
    isFullWidth?: boolean;
    buttonFontSize?: string;
    sectionBackgroundColor?: HexColor;
    freeShipSection?: {
      progressBarColor?: HexColor;
      progressBarBackgroundColor?: HexColor;
    };
  };
  terms?: {
    login?: {
      // this array is a 1-1 mapping with the array in tenant.brandConfig.customTexts.body
      fontSize?: string[];
    };
    review?: {
      // this array is a 1-1 mapping with the array in tenant.brandConfig.customTexts.body
      fontSize?: string[];
    };
    signup?: {
      // this array is a 1-1 mapping with the array in tenant.brandConfig.customTexts.body
      fontSize?: string[];
    };
  };
}

export type ThemeType = ThemeInterface;

// PepDefaultTheme includes only the required
// fields from ThemeInterface. Also, DefaultTheme
// is already taken hence PepDefaultTheme naming
export const PepDefaultTheme: ThemeInterface = {
  name: themeNames.PepDefault,
  color: {
    primary: colors.mediumGray,
    secondary: colors.mediumGray,
    tertiary: colors.mediumGray,
    accent: colors.mediumGray,
    disabled: colors.gray,
    action: colors.green,
    error: colors.red,
    text: colors.darkGray,
  },
  button: {
    primary: { color: colors.mediumGray },
    secondary: { color: colors.green },
    tertiary: { color: colors.mediumGray },
    cancel: { color: colors.mediumGray },
    error: { color: colors.red },
    text: { color: colors.mediumGray },
  },
  typography: {
    heading: {
      xxl: {
        fontSize: {
          desktop: "46px",
        },
      },
      xl: {
        fontSize: {
          desktop: "36px",
        },
      },
      l: {
        fontSize: {
          desktop: "24px",
        },
      },
      m: {
        fontSize: {
          desktop: "18px",
        },
      },
      s: {
        fontSize: {
          desktop: "16px",
        },
      },
      xs: {
        fontSize: {
          desktop: "14px",
        },
      },
      xxs: {
        fontSize: {
          desktop: "14px",
        },
        fontWeight: "400",
      },
    },
  },
};
