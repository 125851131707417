import React, { useContext, useEffect, useRef } from "react";
import styled, { ThemeContext } from "styled-components";
import { HeaderPromoProps } from "../types/header";
import { headerStyles } from "../common/constants";
import { debounce } from "lodash";

const StyledWrapper = styled.div<{
  shouldShow: boolean;
}>`
  position: fixed;
  top: ${({ shouldShow }) => (shouldShow ? headerStyles.heightPromo : "0")};
  font-family: ${({ theme }) => theme.header?.fontFamily};
  height: 60px;
  width: 100%;
  transition: all 500ms linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: ${headerStyles.zIndex}; /* We need this for IE */
`;

export const HeaderWrapper: React.FunctionComponent<HeaderPromoProps> = (
  props
) => {
  const theme = useContext(ThemeContext);
  const { transparentYOffsetThreshold } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const isClientSide = typeof window !== "undefined";

  const setBgColor = () => {
    const transparentBg =
      isClientSide &&
      transparentYOffsetThreshold &&
      (window?.scrollY || window?.scrollY === 0) &&
      window?.scrollY <= transparentYOffsetThreshold;
    const newBackgroundColor = transparentBg
      ? "transparent"
      : theme.header?.shop?.backgroundColor || theme.color.primary;
    if (divRef.current) {
      divRef.current.style.backgroundColor = newBackgroundColor;
    }
  };

  const handleScroll = debounce(() => {
    setBgColor();
  }, 50);

  useEffect(() => {
    if (isClientSide) {
      setBgColor();
      if (transparentYOffsetThreshold) {
        window?.addEventListener("scroll", handleScroll);
        return () => window?.removeEventListener("scroll", handleScroll);
      }
    }
    // Ignoring dep warning for now, would be better fixed using useEvent when it
    // becomes available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StyledWrapper {...props} ref={divRef} />;
};
