import { useState } from "react";
import { ModalType, ModalContextInterface } from "types/modal";

export function useModalProvider(): {
  modalContextValue: ModalContextInterface;
} {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>("");

  const modalContextValue: ModalContextInterface = {
    isOpen: isModalOpen,
    type: modalType,
    open: (type) => {
      setModalOpen(true);
      setModalType(type);
    },
    close: () => setModalOpen(false),
  };

  return { modalContextValue };
}
