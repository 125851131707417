import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { Checkbox } from "../checkbox/Checkbox";
import { ExternalLink } from "../link/ExternalLink";
import { colors } from "../../styles/variables";
import { Alert } from "../alert/Alert";

/**
 * NOTE - This component is present in both v2 and v3 as we transition everything to using v3.
 * Please be sure to make any changes to BOTH, so they're synchronized.
 */

export const StyledTermsWrapper = styled.div`
  padding: 20px;
  background-color: ${colors.lightGray};
  color: ${colors.black};
  font-size: 13px;
  border-radius: 6px;
  margin-top: 20px;
`;

export const StyledTermsAndConditionsLabel = styled.label`
  display: flex;
  gap: 10px;
`;

export const StyledTermsText = styled.div`
  a {
    color: ${({ theme }) => theme.color.primary};
  }
  line-height: 15.51px;
  margin-bottom: 20px;
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
`;

interface TermsAndConditionsInfoProps {
  children: ReactNode;
  termsUrl: string;
  type: "info";
}

interface TermsAndConditionsOptInProps {
  checked: boolean;
  children: ReactNode;
  onChange: () => void;
  showError: boolean;
  termsUrl: string;
  type: "optin";
}

type TermsAndConditionsProps =
  | TermsAndConditionsInfoProps
  | TermsAndConditionsOptInProps;

export const TermsAndConditions: FC<TermsAndConditionsProps> = (props) => {
  const { children, termsUrl, type } = props;

  const Terms = () => (
    <StyledTermsText>
      By accessing this site and/or placing your order you agree to
      PepsiCo&apos;s terms of service. Please see{" "}
      <ExternalLink href={termsUrl}>terms of service</ExternalLink> for more
      information.
    </StyledTermsText>
  );

  if (type === "optin") {
    const { checked, onChange, showError } = props;

    return (
      <StyledTermsWrapper>
        {showError && (
          <StyledAlert type="error">
            You must read and agree to the terms of service before proceeding
          </StyledAlert>
        )}
        <StyledTermsAndConditionsLabel>
          <Checkbox
            hasError={showError}
            checked={checked}
            onChange={onChange}
          />
          <Terms />
        </StyledTermsAndConditionsLabel>
        {children}
      </StyledTermsWrapper>
    );
  } else {
    return (
      <StyledTermsWrapper>
        <Terms />
        {children}
      </StyledTermsWrapper>
    );
  }
};
