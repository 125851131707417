import React, { FC } from "react";
import { ExternalLink } from "../../link/ExternalLink";
import { FooterCopyrightProps } from "../types";
import styled from "styled-components";
import { colors } from "../../../styles/variables";

const StyledFooterCopyright = styled.div`
  font-size: 12px;
  line-height: 20px;
  padding: 10px 0;
  display: inline-block;
  text-align: center;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.footer?.copyright?.backgroundColor || "#fff"};
  color: ${({ theme }) =>
    theme.footer?.copyright?.textColor || colors.mediumGray};
`;

export const FooterCopyright: FC<FooterCopyrightProps> = ({
  brandName,
  termsAndConditionsLink,
  privacyPolicyLink,
}) => {
  const year = new Date().getFullYear();

  return (
    <StyledFooterCopyright data-testid="footer-copyright">
      © {year} {brandName}. All rights reserved.{" "}
      {!!termsAndConditionsLink && (
        <ExternalLink href={termsAndConditionsLink}>
          Terms & Conditions.{" "}
        </ExternalLink>
      )}
      {privacyPolicyLink && (
        <ExternalLink href={privacyPolicyLink}>Privacy Policy</ExternalLink>
      )}
    </StyledFooterCopyright>
  );
};
