import {
  ApolloClient,
  DefaultOptions,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import {
  logNonArrayGraphQLErrors,
  logServerNetworkError,
} from "@pepdirect/helpers/logAndCaptureInSentry";
import { endpoints, isLocalEnv } from "config";

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache" as const,
  },
  query: {
    fetchPolicy: "no-cache" as const,
  },
  mutate: {
    fetchPolicy: "no-cache" as const,
  },
};

// https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    const { operationName } = operation;
    /*
    - expected errors from BE, returned as 200 status code
    - do not log to DataDog/Sentry; any major errors returned as 200 (e.g.
      Braintree payment failures) will have already been logged on BE and
      are technically BE errors anyway
  */
    if (graphQLErrors) {
      if (Array.isArray(graphQLErrors)) {
        graphQLErrors.forEach((e) => {
          const { message, extensions } = e;
          // console.warn only in dev for debugging
          if (isLocalEnv) {
            const errorMessage =
              "[GraphQL 200 error]: " +
              `message: "${message}", ` +
              `operation name: ${operationName}` +
              `${
                extensions
                  ? `, extensions: ${JSON.stringify(extensions, null, 2)}`
                  : ""
              }`;
            console.warn(errorMessage);
          }
        });
      } else {
        logNonArrayGraphQLErrors({ graphQLErrors, operationName, response });
      }
    }

    // unexpected errors from BE, returned as 4-5xx status code
    if (networkError) {
      logServerNetworkError(networkError, operationName);
    }
  }
);

const uri = `${endpoints.api}/graphql`;

const link = from([
  errorLink,
  new HttpLink({
    credentials: "include",
    uri,
  }),
]);

const client = new ApolloClient({
  credentials: "include",
  defaultOptions,
  cache: new InMemoryCache(),
  uri,
  link,
});

export default client;
