import React from "react";
import Link from "next/link";
import styled from "styled-components";
import {
  genericTouchHandler,
  redirectKeyPressToClick,
} from "../common/helpers";
import { HeaderIconSvg } from "../common/icons";
import { HeaderCartProps } from "../types/header";

const StyledCart = styled.div`
  margin-left: 20px;
  height: inherit;
  cursor: pointer;

  svg {
    width: 18px;

    path {
      fill: ${({ theme }) => theme.header?.shop?.navLinkColor || "#fff"};
    }
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const NumOfItems = styled.span`
  color: ${({ theme }) => theme.header?.shop?.navLinkColor || "#fff"};
  margin-left: 5px;
  font-size: 14px;
`;

export const HeaderCart: React.FunctionComponent<HeaderCartProps> = ({
  onClickHandler,
  shoppingCartUrl,
  numOfItems,
}) => {
  return (
    <StyledCart className="header-shopping-cart">
      <StyledLink
        href={shoppingCartUrl || "/cart"}
        className="svg-icon"
        onClick={onClickHandler}
        onKeyDown={redirectKeyPressToClick}
        onTouchStart={genericTouchHandler}
        tabIndex={0}
      >
        {HeaderIconSvg.shoppingCart}
        <NumOfItems>{numOfItems !== undefined && `(${numOfItems})`}</NumOfItems>
      </StyledLink>
    </StyledCart>
  );
};
