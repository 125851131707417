import React, { FC } from "react";
import styled from "styled-components";
import { FooterNavigationLinks } from "./FooterNavigationLinks";
import { FooterNavigationProps } from "../types";
import { Device } from "../../../helpers/screenSizes";

const StyledFooterNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media ${Device.notLaptop} {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const FooterNavigation: FC<FooterNavigationProps> = ({ navigation }) => {
  return (
    <StyledFooterNavigation data-testid="footer-navigation">
      {navigation.map((navItem, index) => (
        <FooterNavigationLinks key={index} {...navItem} />
      ))}
    </StyledFooterNavigation>
  );
};
