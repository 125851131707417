import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { getZipCodeFromCookie, setZipCodeCookie } from "services/zipCode";
import { ZipCodeContextInterface } from "context/zipCode";
import client from "services/graphql/client";
import {
  RestrictedItemsByZipCodeDocument,
  RestrictedItemsByZipCodeQuery,
  RestrictedItemsByZipCodeQueryVariables,
} from "services/graphql/generated";

export function useZipCodeProvider(): {
  zipCodeContextValue: ZipCodeContextInterface;
} {
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [blockedItemsLoading, setBlockedItemsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blockedItems, setBlockedItems] = useState<
    NonNullable<
      NonNullable<
        RestrictedItemsByZipCodeQuery["restrictedItemsByZipCode"]
      >["restrictedItems"]
    >
  >([]);
  const [getRestrictedItems] = useLazyQuery<
    RestrictedItemsByZipCodeQuery,
    RestrictedItemsByZipCodeQueryVariables
  >(RestrictedItemsByZipCodeDocument, { client });

  useEffect(() => {
    const acquireZip = async () => {
      const cookieZip = getZipCodeFromCookie();
      if (cookieZip) {
        setZipCode(cookieZip);
        return;
      }
    };

    if (!zipCode) {
      acquireZip();
    }
  }, [zipCode]);

  useEffect(() => {
    const getBlockedItems = async () => {
      try {
        setBlockedItemsLoading(true);
        const { data } = await getRestrictedItems({
          variables: { zip: zipCode || "" },
        });
        const items = data?.restrictedItemsByZipCode?.restrictedItems || [];
        setBlockedItems(items);
      } catch (e) {
        // logged in errorLink
      } finally {
        setBlockedItemsLoading(false);
      }
    };

    if (zipCode) {
      getBlockedItems();
    } else {
      setBlockedItemsLoading(false);
    }
  }, [zipCode, getRestrictedItems]);

  const zipCodeContextValue: ZipCodeContextInterface = {
    zipCode,
    userSetZipCode: (zip: string) => {
      setZipCode(zip);
      setZipCodeCookie(zip);
    },
    // TEMP PROD FIX
    // blockedItems: blockedItems.map(({ id }) => id),
    blockedItems: [],
    blockedItemsLoading,
  };

  return { zipCodeContextValue };
}
