import React from "react";
import styled from "styled-components";
import { Device } from "../../../../helpers/screenSizes";
import { redirectKeyPressToClick } from "../common/helpers";
import { HeaderIconSvg } from "../common/icons";
import { HeaderLocationProps } from "../types/header";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${Device.mobile} {
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    height: 60px;
    padding-left: 20px;
    svg {
      path {
        fill: ${({ theme }) => theme.color.primary};
      }
    }
  }

  @media ${Device.notMobile} {
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .svg-icon {
    padding-right: 5px;

    svg {
      width: 18px;
    }
  }
`;

const LocationText = styled.span`
  text-decoration: underline;
  font-size: 14px;
`;

export const HeaderLocation: React.FunctionComponent<HeaderLocationProps> = ({
  showLocation,
  onClickHandler,
  zipCode,
  keepMobileMenuOpen,
}) => {
  if (!showLocation) {
    return null;
  }
  const onClicks = (event: React.MouseEvent) => {
    // close the mobile menu, if mobile
    if (keepMobileMenuOpen) {
      keepMobileMenuOpen(false);
    }
    // take the handled action
    onClickHandler(event);
  };
  return (
    <StyledWrapper
      className="header-location"
      data-testid="ui-header-location"
      tabIndex={0}
      onKeyDown={redirectKeyPressToClick}
      onClick={onClicks}
    >
      <span className="svg-icon">{HeaderIconSvg.location}</span>
      <LocationText>{zipCode ? zipCode : "Deliver to"}</LocationText>
    </StyledWrapper>
  );
};
