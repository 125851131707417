import React from "react";
import styled from "styled-components";
import { Device } from "../../../../helpers/screenSizes";
import { HeaderUserProps } from "../types/header";

const StyledUserWrapper = styled.div`
  @media ${Device.laptop} {
    display: none;
  }

  height: 60px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.header?.shop?.backgroundColor || "#fff"};
  border-bottom: 1px solid #ebebeb;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) =>
    theme.header?.shop?.mobileLinkColor || theme.color.primary};
  width: 50%;
  text-align: center;
  cursor: pointer;

  &.full {
    width: 100%;
  }
`;

const StyledDivider = styled.div`
  height: 60px;
  border-right: 1px solid #ebebeb;
`;

export const MobileUser: React.FunctionComponent<HeaderUserProps> = ({
  isLoggedIn,
  accountUrl,
  onAccountClicked,
  logOutUrl,
  onLogOutClicked,
  logInUrl,
  onLogInClicked,
  signUpUrl,
  onSignUpClicked,
}) => {
  return isLoggedIn ? (
    <StyledUserWrapper>
      <StyledLink href={accountUrl} onClick={onAccountClicked}>
        Account
      </StyledLink>
      <StyledDivider />
      <StyledLink href={logOutUrl} onClick={onLogOutClicked}>
        Log Out
      </StyledLink>
    </StyledUserWrapper>
  ) : !!signUpUrl || !!onSignUpClicked ? (
    <StyledUserWrapper>
      <StyledLink href={logInUrl} onClick={onLogInClicked}>
        Log In
      </StyledLink>
      <StyledDivider />
      <StyledLink href={signUpUrl} onClick={onSignUpClicked}>
        Sign Up
      </StyledLink>
    </StyledUserWrapper>
  ) : (
    <StyledUserWrapper>
      <StyledLink className="full" href={logInUrl} onClick={onLogInClicked}>
        Log In
      </StyledLink>
    </StyledUserWrapper>
  );
};
