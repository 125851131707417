import React, { FC } from "react";
import styled from "styled-components";
import { Device } from "../../helpers/screenSizes";

// interfaces & children components
import { FooterProps } from "./types";
import { FooterBranding } from "./footer-children/FooterBranding";
import { FooterNavigation } from "./footer-children/FooterNavigation";
import { FooterCopyright } from "./footer-children/FooterCopyright";

/**
 * The footer consists of 3 sections:
 * 1. Branding + social media, which is a smaller logo plus icons with links to its various social media.
 * 2. List of links, with an optional category name on top (e.g., SHOP: Product Bundles, Sports Drinks, etc.).
 * 3. Copyright info with link to terms and privacy policy.
 */

const FooterContent = styled.div`
  background-color: ${({ theme }) =>
    theme.footer?.backgroundColor || theme.color.primary};
  color: ${({ theme }) => theme.footer?.textColor || "#fff"};
  height: auto;
  padding: 60px 80px 80px 60px;

  a {
    color: ${({ theme }) => theme.footer?.textColor || "#fff"};
    text-decoration: none;
  }

  @media ${Device.mobile} {
    padding: 40px 40px 80px;
  }
`;

const BrandingAndNav = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${Device.notLaptop} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const Footer: FC<FooterProps> = ({
  branding,
  copyright,
  navigation,
}) => {
  return (
    <footer data-testid="footer">
      <FooterContent>
        <BrandingAndNav>
          <FooterBranding {...branding} />
          <FooterNavigation navigation={navigation} />
        </BrandingAndNav>
      </FooterContent>

      <FooterCopyright {...copyright} />
    </footer>
  );
};
