import { ThemeInterface, themeNames } from "../theme";
import { colors } from "../../styles/variables";
import { fontWeights, HexColor } from "@pepdirect/shared/types";

type PepsiCoShopColorNames =
  | "pepsiCoShopBlue"
  | "orange"
  | "brown"
  | "lightBlue";

const PEPSICOSHOP_COLORS: Record<PepsiCoShopColorNames, HexColor> = {
  pepsiCoShopBlue: "#28458e",
  orange: "#e07647",
  brown: "#8e6451",
  lightBlue: "#cad7ff",
};

const SHARED_HEADING_STYLES = {
  fontWeight: fontWeights["700"],
  color: PEPSICOSHOP_COLORS.pepsiCoShopBlue,
  lineHeight: "1.3",
};

export const PepsiCoShopTheme: ThemeInterface = {
  name: themeNames.PepsiCoShop,
  color: {
    primary: PEPSICOSHOP_COLORS.pepsiCoShopBlue,
    secondary: PEPSICOSHOP_COLORS.orange,
    tertiary: PEPSICOSHOP_COLORS.brown,
    accent: PEPSICOSHOP_COLORS.lightBlue,
    disabled: colors.lightestGray,
    action: colors.green,
    error: colors.red,
    text: colors.darkGray,
  },
  button: {
    primary: {
      color: PEPSICOSHOP_COLORS.pepsiCoShopBlue,
    },
    secondary: {
      color: colors.green,
    },
    tertiary: {
      color: PEPSICOSHOP_COLORS.pepsiCoShopBlue,
      hollow: true,
    },
    cancel: {
      color: colors.mediumGray,
      hollow: true,
    },
    error: {
      color: colors.red,
      hollow: true,
    },
    text: {
      color: PEPSICOSHOP_COLORS.pepsiCoShopBlue,
    },
    fontWeight: "700",
  },
  typography: {
    body: {
      lineHeight: "1.5",
    },
    heading: {
      xxl: {
        fontSize: {
          desktop: "46px",
          mobile: "32px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      xl: {
        fontSize: {
          desktop: "32px",
          mobile: "24px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      l: {
        fontSize: {
          desktop: "24px",
          mobile: "20px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      m: {
        fontSize: {
          desktop: "18px",
          mobile: "18px",
        },
        ...SHARED_HEADING_STYLES,
      },
      s: {
        fontSize: {
          desktop: "16px",
          mobile: "16px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xs: {
        fontSize: {
          desktop: "14px",
          mobile: "14px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xxs: {
        fontSize: {
          desktop: "14px",
          mobile: "14px",
        },
        fontWeight: fontWeights["400"],
      },
    },
  },
  footer: {
    copyright: {
      backgroundColor: PEPSICOSHOP_COLORS.lightBlue,
      textColor: PEPSICOSHOP_COLORS.pepsiCoShopBlue,
    },
  },
};
