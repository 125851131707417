import React from "react";
import { CartItemUpdateDto } from "@pepdirect/shared/types";
import {
  CartFragment,
  CreateCartFromOrderMutation,
} from "services/graphql/generated";

export interface CartContextInterface {
  cart: CartFragment | null;
  loading: boolean;
  refreshCart(): void;
  addCartItem(_dto: CartItemUpdateDto): Promise<void>;
  updateCartItem(_dto: CartItemUpdateDto): Promise<void>;
  getSize(): number | undefined;
  mergeCart(
    cartFromOrder: CreateCartFromOrderMutation["createTemporaryCartFromOrder"]
  ): Promise<CartFragment | void>;
}

export const CartContext = React.createContext<CartContextInterface>({
  cart: null,
  loading: true,
  refreshCart: () => {},
  mergeCart: async () => {},
  addCartItem: async (_dto: CartItemUpdateDto) => {},
  updateCartItem: async (_dto: CartItemUpdateDto) => {},
  getSize: () => {
    return 0;
  },
});
