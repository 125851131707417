import React from "react";
import {
  LogContextValueInterface,
  Cart,
  Product,
  LogPageViewParams,
} from "@pepdirect/shared/types";
import { CartFragment } from "services/graphql/generated";

export const LogContext = React.createContext<LogContextValueInterface>({
  useLogPageView: (
    _params: LogPageViewParams,
    _product?: Product | null,
    _cart?: Cart | CartFragment | null,
    _key?: string
  ) => {},
});
