import React, { FC } from "react";
import Image from "next/image";
import getConfig from "next/config";
import styled from "styled-components";
import { ExternalLink } from "../../link/ExternalLink";
import { FooterIconSvg } from "../common/icons";
import { getCloudflareLoader } from "@pepdirect/helpers/cloudflareImages";
import { Device } from "../../../helpers/screenSizes";
import { FooterBrandingProps } from "../types";

const { publicRuntimeConfig } = getConfig();

const StyledFooterBranding = styled.div`
  display: flex;
  flex-direction: column;

  @media ${Device.notLaptop} {
    padding-top: 80px;
  }
`;

const BrandLogoImg = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;

  /*
    the below styles here and on the img
    should allow all images to fit within
    the container correctly
  */
  width: 160px;
  height: 50px;
  object-fit: contain;
  object-position: left;

  img {
    object-fit: contain;
    width: 160px;
    height: 50px;
    object-position: left;
    /* for IE 11; see https://stackoverflow.com/a/43227016 */
    flex: 1;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
`;

const BrandLogo = styled.span`
  padding-right: 0.9rem;

  svg {
    width: 1.1rem;
    height: 1.1rem;
    object-fit: contain;
    object-position: left;
    path {
      fill: ${({ theme }) => theme.footer?.textColor || "#fff"};
    }
  }
`;

const PhoneAndAddress = styled.div`
  padding-top: 11px;
  white-space: nowrap;

  p {
    margin: 0;
    font-size: 12px;
  }
`;

export const FooterBranding: FC<FooterBrandingProps> = ({
  logoImgLink,
  instagramLink,
  facebookLink,
  twitterLink,
  pinterestLink,
  linkedInLink,
  youtubeLink,
  phoneNumber,
  addressLine1,
  addressLine2,
}) => {
  const hasSocialMedia =
    !!instagramLink ||
    !!facebookLink ||
    !!twitterLink ||
    !!pinterestLink ||
    !!linkedInLink ||
    !!youtubeLink;

  return (
    <StyledFooterBranding data-testid="footer-branding">
      <BrandLogoImg>
        {!!logoImgLink && (
          <Image
            src={logoImgLink}
            layout="fill"
            objectFit="contain"
            alt="Brand logo"
            loader={getCloudflareLoader(publicRuntimeConfig.cdn)}
          />
        )}
      </BrandLogoImg>

      {hasSocialMedia && (
        <SocialMediaIcons>
          {!!instagramLink && (
            <ExternalLink href={instagramLink}>
              <BrandLogo>{FooterIconSvg.instagram}</BrandLogo>
            </ExternalLink>
          )}
          {!!facebookLink && (
            <ExternalLink href={facebookLink}>
              <BrandLogo>{FooterIconSvg.facebook}</BrandLogo>
            </ExternalLink>
          )}
          {!!twitterLink && (
            <ExternalLink href={twitterLink}>
              <BrandLogo>{FooterIconSvg.twitter}</BrandLogo>
            </ExternalLink>
          )}
          {!!pinterestLink && (
            <ExternalLink href={pinterestLink}>
              <BrandLogo>{FooterIconSvg.pinterest}</BrandLogo>
            </ExternalLink>
          )}
          {!!linkedInLink && (
            <ExternalLink href={linkedInLink}>
              <BrandLogo>{FooterIconSvg.linkedIn}</BrandLogo>
            </ExternalLink>
          )}
          {!!youtubeLink && (
            <ExternalLink href={youtubeLink}>
              <BrandLogo>{FooterIconSvg.youtube}</BrandLogo>
            </ExternalLink>
          )}
        </SocialMediaIcons>
      )}

      <PhoneAndAddress>
        {!!phoneNumber && <p>{phoneNumber}</p>}
        {!!addressLine1 && <p>{addressLine1}</p>}
        {!!addressLine2 && <p>{addressLine2}</p>}
      </PhoneAndAddress>
    </StyledFooterBranding>
  );
};
