import styled from "styled-components";
import { colors } from "../../styles/variables";

export const StyledContainer = styled.div`
  margin: auto;
  max-width: 448px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
`;

export const StyledLogo = styled.img`
  width: 160px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const StyledParagraph = styled.div`
  padding-top: 30px;
  margin-bottom: 60px;
  color: ${colors.gray600};
`;
