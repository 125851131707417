import React from "react";
import styled from "styled-components";
import { HexColor } from "@pepdirect/shared/types";

// This is the "Squeeze" hamburger from https://jonsuh.com/hamburgers/
const StyledOuter = styled.button`
  cursor: pointer;
  display: inline-block;
  font: inherit;
  overflow: visible;
  border: 0;
  margin: 0;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  background-color: transparent;
  padding-left: 0;
`;

const StyledBox = styled.span`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const StyledInner = styled.span<StyledMobileIcon>`
  display: block;
  top: 50%;
  background-color: #ffffff;
  transition-duration: 0.175s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &,
  &::before,
  &::after {
    width: 30px;
    height: 2px;
    border-radius: 5px;
    background-color: ${({ color }) => color || "#ffffff"};
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -10px;
    transition: top 0.12s 0.12s ease, opacity 0.075s ease;
  }

  &::after {
    bottom: -10px;
    transition: bottom 0.12s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  ${({ isOpen }) =>
    isOpen &&
    `transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.12s ease,
                  opacity 0.075s 0.12s ease;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.12s ease,
                  transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    `}
`;

interface StyledMobileIcon {
  isOpen: boolean;
  color?: HexColor;
}
export interface MobileMenuIconProps extends StyledMobileIcon {
  onClick: (isOpen: boolean) => void;
}

export const MobileMenuIcon: React.FunctionComponent<MobileMenuIconProps> = ({
  isOpen,
  color,
  onClick,
}) => {
  return (
    <StyledOuter onClick={() => onClick(!isOpen)}>
      <StyledBox>
        <StyledInner isOpen={isOpen} color={color} />
      </StyledBox>
    </StyledOuter>
  );
};
