import React from "react";
import styled from "styled-components";
import { Device } from "../../../../helpers/screenSizes";
import { MobileMenuProps } from "../types/header";
import { NavMenu } from "./NavMenu";

const StyledNavBar = styled.div`
  @media ${Device.notLaptop} {
    display: none;
  }
  margin-left: 20px;
`;

export const HeaderNavBar: React.FunctionComponent<MobileMenuProps> = (
  props
) => {
  return (
    <StyledNavBar>
      <NavMenu {...props} />
    </StyledNavBar>
  );
};
