import React, { FC, ReactNode } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { FieldInput } from "../fields/FieldInput";
import { Button } from "../button/Button";
import { Heading } from "../heading/Heading";
import { validateWithYup, loginSchema } from "./validation";
import { colors } from "../../styles/variables";
import { TermsAndConditions } from "../termsAndConditions/TermsAndConditions";
import { BrandConfigCustomizationsImage } from "../../graphql/generated";

const StyledLoginForm = styled.div`
  margin: auto;
  max-width: 448px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
`;

const Logo = styled.img`
  width: 160px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const AccountLogo = styled.img`
  margin-bottom: 70px;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const LoginButton = styled.div`
  padding: 10px 0;
`;

const Error = styled.span`
  font-size: 14px;
  line-height: 1.3;
  color: ${({ theme }) => theme.color.error};
  margin-top: 5px;
  display: block;
  margin-bottom: 10px;
  text-align: center;
`;

const ResetPassword = styled.div`
  button {
    font-size: 12px;
    color: ${colors.mediumGray};
    text-decoration: underline;
    font-weight: normal;
  }
`;

interface LoginFormProps {
  children?: ReactNode;
  error: string;
  fallbackUrl?: string;
  goToForgotPassword: () => void;
  isLoading: boolean;
  logoSrc?: string;
  accountLogo?: BrandConfigCustomizationsImage;
  onSubmit: (formValue: LoginDTO) => void;
  privacyUrl?: string;
  termsUrl?: string;
  signinPageTitle?: string | null;
}

interface LoginDTO {
  login: string;
  password: string;
}

export const LoginForm: FC<LoginFormProps> = ({
  accountLogo,
  children,
  error,
  goToForgotPassword,
  isLoading,
  logoSrc,
  onSubmit,
  termsUrl,
  signinPageTitle,
}) => {
  return (
    <StyledLoginForm>
      {!!logoSrc && <Logo src={logoSrc} alt="shop logo" />}

      {!!accountLogo && <AccountLogo src={accountLogo.src} alt="Account" />}

      <Heading level="h2" size="l" color={colors.darkGray}>
        {signinPageTitle}
      </Heading>
      <Form
        onSubmit={(formValue: LoginDTO) => onSubmit(formValue)}
        validate={validateWithYup(loginSchema)}
        render={({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FieldInput
              label="Email Address"
              name="login"
              autoComplete="email"
            />
            <FieldInput
              label="Password"
              name="password"
              type="password"
              component="input"
              autoComplete="current-password"
            />
            <TermsAndConditions termsUrl={termsUrl || ""} type="info">
              <LoginButton>
                <Button htmlType="submit" loading={isLoading} fullWidth>
                  Login
                </Button>
              </LoginButton>
              <ResetPassword>
                <Button type="text" onClick={goToForgotPassword}>
                  Reset password
                </Button>
              </ResetPassword>
            </TermsAndConditions>
            {error && <Error>{error}</Error>}
          </StyledForm>
        )}
      />
      {children}
    </StyledLoginForm>
  );
};
