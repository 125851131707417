// Polyfill for IE 11
import "intersection-observer";
import React from "react";
import Router from "next/router";
import NProgress from "nprogress";
import classnames from "classnames";
import { endpoints, isLocalEnv } from "config";
import { ModalContext } from "context/modal";
import { AppContext } from "context/app";
import { UserContext } from "context/user";
import { CartContext } from "context/cart";
import { ZipCodeContext } from "context/zipCode";
import { LogContext } from "context/log";
import { useLogProvider } from "context/hooks/useLogProvider";
import { useUserProvider } from "context/hooks/useUserProvider";
import { useCartProvider } from "context/hooks/useCartProvider";
import { useModalProvider } from "context/hooks/useModalProvider";
import { useAppProvider } from "context/hooks/useAppProvider";
import { useZipCodeProvider } from "context/hooks/useZipCodeProvider";
import { Ccm } from "@pepdirect/ui/ccm";

import "styles/index.scss";

/* Swiper */
import "swiper/swiper.scss";
import "swiper/components/a11y/a11y.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/zoom/zoom.scss";

/* Customizing swiper */
import "styles/swiper.scss";

/* Customizing modal */
import "styles/modal.scss";

import { PepsiCoShopTheme } from "@pepdirect/ui/theme/PepsiCoShopTheme";
import { useFontTracking } from "@pepdirect/helpers/useFontTracking";
import { FontTrackingiFrame } from "@pepdirect/ui/fontTracking";
import { NavHeader } from "components/Header";
import { NavFooter } from "components/Footer";
import { RootModal } from "components/Modals/Modal";
import App, {
  AppProps,
  AppInitialProps,
  AppContext as NextAppContext,
} from "next/app";

/* Importing and customizing nprogress */
import "styles/nprogress.scss";
import Head from "next/head";
import {
  META_TITLE,
  META_DESCRIPTION,
  META_DEFAULT_OG_IMAGE,
  CANONICAL_URL_BASE,
  PRIMARY_COLOR,
} from "constants/meta";

import "styles/stylingOverrides.scss";
import { ThemeProvider } from "styled-components";
import { headerPromotion } from "config";
import {
  isPublicRoute,
  isServerSide,
  serverSideRedirect,
} from "helpers/routing";
import { isLoggedIn } from "helpers/user";

import { addGitShaToWindow } from "@pepdirect/helpers/gitSha";
import { generateFontFaces } from "@pepdirect/helpers/fonts";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import client from "services/graphql/client";
import {
  ActiveAccountDocument,
  ActiveAccountQuery,
  CurrentUserDocument,
  CurrentUserQuery,
  FeatureFlagsDocument,
  FeatureFlagsQuery,
  FeatureFlagsQueryVariables,
  TenantDocument,
  TenantQuery,
  TenantQueryVariables,
} from "services/graphql/generated";
import createSsrClient from "services/graphql/ssr-client";
import { AnalyticsSessionParamsContext } from "context/analyticsSessionParams";
import { useAnalyticsSessionParamsProvider } from "context/hooks/useAnalyticsSessionParamsProvider";
addGitShaToWindow();

// Init progress bar
NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Scroll to top after router.push();
Router.events.on("routeChangeComplete", () => window?.scrollTo(0, 0));

interface B2BAppInitialProps extends AppInitialProps {
  featureFlags: FeatureFlagsQuery;
  tenant: TenantQuery;
  user: CurrentUserQuery["currentUser"] | null;
}

interface B2BAppProps extends AppProps {
  featureFlags: FeatureFlagsQuery;
  tenant: TenantQuery;
  user: CurrentUserQuery["currentUser"] | null;
}

B2B.getInitialProps = async (
  appContext: NextAppContext
): Promise<B2BAppInitialProps> => {
  let gqlClient: ApolloClient<NormalizedCacheObject>;
  let user: CurrentUserQuery["currentUser"] | null = null;
  let account: ActiveAccountQuery["activeAccount"] | null = null;

  if (isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext?.ctx?.req);
  } else {
    gqlClient = client;
  }

  try {
    const userData = await gqlClient.query<CurrentUserQuery>({
      query: CurrentUserDocument,
    });
    user = userData?.data?.currentUser;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  try {
    const accountData = await gqlClient.query<ActiveAccountQuery>({
      query: ActiveAccountDocument,
    });
    account = accountData.data.activeAccount;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    e &&
      console.error(
        JSON.stringify({ message: e.message, stack: e.stack, name: e.name })
      );
  }

  // When we add cookies to the request for tenant and featureFlags queries,
  // if the cookie is not valid, then they return 401 unauthenticated error.
  // To prevent this, we detach cookies from the request before making request to them
  // if the user cookie is not valid. If cookie is not set, then this would not be a problem.
  if (!isLoggedIn(user) && isServerSide(appContext)) {
    gqlClient = createSsrClient(appContext?.ctx?.req, true);
  }

  if (isServerSide(appContext) && !isLoggedIn(user)) {
    /* Redirect logged-out users on server side */
    if (!isPublicRoute(appContext.router.pathname)) {
      serverSideRedirect(appContext, "/sign-in");
    }
  }

  if (
    isServerSide(appContext) &&
    account?.tags?.includes("ATD") &&
    appContext.router.pathname === "/"
  ) {
    serverSideRedirect(appContext, "/home");
  }

  /* TODO: maybe have some backup values, in case getTenant/fetFeatureFlags fail? */
  /* eslint-disable no-useless-catch */
  try {
    const [appProps, tenantData, featureFlagsData] = await Promise.all([
      App.getInitialProps(appContext),
      client.query<TenantQuery, TenantQueryVariables>({
        query: TenantDocument,
      }),
      client.query<FeatureFlagsQuery, FeatureFlagsQueryVariables>({
        query: FeatureFlagsDocument,
      }),
    ]);

    return {
      ...appProps,
      user,
      featureFlags: featureFlagsData.data,
      tenant: tenantData?.data,
    };
  } catch (e) {
    // make sure to re-throw after catch logs in errorLink so we show 500 error
    throw e;
  }
};

// Revisit this later if layout needs to change https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export default function B2B({
  Component,
  pageProps,
  featureFlags,
  tenant,
  user,
}: B2BAppProps): JSX.Element {
  const { appContextValue } = useAppProvider(
    featureFlags.featureFlags,
    tenant.tenant
  );
  const { modalContextValue } = useModalProvider();
  const { userContextValue } = useUserProvider(user, client);
  const { zipCodeContextValue } = useZipCodeProvider();
  const { cartContextValue } = useCartProvider(
    client,
    userContextValue.currentUserId
  );
  const { useLogPageView } = useLogProvider(userContextValue.currentUserId);
  const { analyticsParamsValue } = useAnalyticsSessionParamsProvider();

  const fonts = tenant.tenant?.brandConfig.trackingFonts || [];
  const title = tenant.tenant?.brandConfig.title || "PepsiCoShop";
  const trackFontResources = useFontTracking(fonts, title);

  const pageWrapperClasses = classnames("page-wrapper", {
    "with-promo": headerPromotion?.shouldShow,
  });

  return (
    <>
      <Head>
        {/* Meta tags not intended to change */}
        <meta name="theme-color" content={PRIMARY_COLOR} />
        <meta name="og:site_name" content={META_TITLE} />
        <meta name="og:price:currency" content="USD" />
        {/* Meta tags */}
        <meta name="og:title" content={META_TITLE} key="og:title" />
        <meta name="description" content={META_DESCRIPTION} key="description" />
        <meta
          name="og:description"
          content={META_DESCRIPTION}
          key="og:description"
        />
        <meta name="og:image" content={META_DEFAULT_OG_IMAGE} key="og:image" />
        <meta name="og:type" content="website" key="og:type" />
        <meta name="og:url" content={CANONICAL_URL_BASE} key="og:url" />
      </Head>
      <Ccm cdn={endpoints.cdn} />
      <style
        dangerouslySetInnerHTML={{
          __html: generateFontFaces(
            tenant?.tenant?.brandConfig?.customizations.fonts || []
          ),
        }}
      />
      {trackFontResources.map((resource) => (
        <FontTrackingiFrame
          resource={resource}
          key={resource}
          isLocalEnv={isLocalEnv}
        />
      ))}
      <ApolloProvider client={client}>
        <AppContext.Provider value={appContextValue}>
          <ThemeProvider theme={PepsiCoShopTheme}>
            <ModalContext.Provider value={modalContextValue}>
              <UserContext.Provider value={userContextValue}>
                <CartContext.Provider value={cartContextValue}>
                  <LogContext.Provider value={{ useLogPageView }}>
                    <ZipCodeContext.Provider value={zipCodeContextValue}>
                      <AnalyticsSessionParamsContext.Provider
                        value={analyticsParamsValue}
                      >
                        <div className="page-layout">
                          <NavHeader />
                          <main className={pageWrapperClasses}>
                            <Component {...pageProps} />
                          </main>
                          <div>
                            <NavFooter />
                          </div>
                        </div>
                        <RootModal />
                      </AnalyticsSessionParamsContext.Provider>
                    </ZipCodeContext.Provider>
                  </LogContext.Provider>
                </CartContext.Provider>
              </UserContext.Provider>
            </ModalContext.Provider>
          </ThemeProvider>
        </AppContext.Provider>
      </ApolloProvider>
    </>
  );
}
