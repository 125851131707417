import React from "react";

export const genericKeyDownHandler = (event: React.KeyboardEvent): void => {
  if (event.key === " ") {
    event.stopPropagation();
  }
};

export const genericTouchHandler = (
  event: React.TouchEvent<HTMLElement>
): void => {
  // used for when an onTouchStart and onClick event both exists in
  // an element so that it doesn't inadvertently trigger both
  event.preventDefault();
  event.currentTarget.click();
};

export const redirectKeyPressToClick = (event: React.KeyboardEvent): void => {
  if (event.key === "Enter" || event.key === " ") {
    const currentElement = event.currentTarget as HTMLElement;
    currentElement.click();
  }
};
