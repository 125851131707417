import React, { FC } from "react";
import Script from "next/script";
import styled from "styled-components";

const StyledConsent = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 100000;
`;

interface CcmProps {
  cdn: string;
}

export const Ccm: FC<CcmProps> = ({ cdn }) => (
  <>
    <Script
      async
      crossOrigin=""
      src="https://consent.trustarc.com/notice?domain=pepsico-na-ecomm.com&c=teconsent&gtm=1&text=true&js=nj&noticeType=bb&cookieLink=https://www.pepsico.com/legal/privacy"
    />
    <Script src={`${cdn}/scripts/ccm/ccmScript.js`} />
    <StyledConsent id="consent_blackbar" />
  </>
);
