import { useRouter } from "next/router";

interface partialUrl {
  pathname: string;
  query: Record<string, string | string[]>;
}
export function useRouterWithRedirect(): {
  getUrlWithRedirect(_pathname: string): partialUrl;
  pushPreserveRedirect(_pathname: string): void;
  backToRedirect(): void;
} {
  const router = useRouter();

  const getUrlWithRedirect = (pathname: string): partialUrl => {
    const { redirect, redirectAs } = router.query;

    const newRedirect = router.route;
    const newRedirectAs = router.asPath;

    const query = {
      redirect: redirect || newRedirect,
      redirectAs: redirectAs || newRedirectAs,
    };
    return {
      pathname,
      query,
    };
  };

  const pushPreserveRedirect = (pathname: string) => {
    router.push(getUrlWithRedirect(pathname));
  };

  const backToRedirect = () => {
    router.push(
      (router.query.redirect as string) || "/",
      (router.query.redirectAs as string) || "/"
    );
  };

  return { pushPreserveRedirect, backToRedirect, getUrlWithRedirect };
}
