import React, { FC } from "react";
import { StyledContainer, StyledLogo, StyledParagraph } from "./index.styled";
import { Heading } from "../Heading";
import { colors } from "../../styles/variables";
import { Button } from "../Button";

interface UpdatePasswordProps {
  logoSrc?: string;
  onClose?: () => void;
}

export const UpdatePassword: FC<UpdatePasswordProps> = ({
  logoSrc,
  onClose,
}) => (
  <StyledContainer>
    {!!logoSrc && <StyledLogo src={logoSrc} alt="shop logo" />}

    <Heading level="h2" size="l" color={colors.gray900}>
      Check Your Email
    </Heading>

    <StyledParagraph>
      For your account&apos;s security, a password update is needed. We&apos;ve
      sent an email with a secure link to complete this process. Please check
      your inbox (and spam folder if needed) to proceed. Thank you for helping
      us keep your account safe!
    </StyledParagraph>

    {onClose && <Button onClick={onClose}>Return to Shopping</Button>}
  </StyledContainer>
);
