import React from "react";
import styled, { css } from "styled-components";
import { Device } from "../../../helpers/screenSizes";

// interfaces, styles, & children components
import { HeaderProps } from "./types/header";
import { headerStyles } from "./common/constants";
import { HeaderPromo } from "./header-children/HeaderPromo";
import { HeaderWrapper } from "./header-children/HeaderWrapper";
import { HeaderLocation } from "./header-children/HeaderLocation";
import { HeaderLogo } from "./header-children/HeaderLogo";
import { HeaderNavBar } from "./header-children/HeaderNavBar";
import { HeaderCart } from "./header-children/HeaderCart";
import { HeaderUser } from "./header-children/HeaderUser";
import { HeaderNavBarMobile } from "./header-children/HeaderNavBarMobile";
import { HeaderSubNav } from "./header-children/HeaderSubNav";
import { Button } from "../../button/Button";

const StyledHeader = styled.div`
  position: fixed;
  z-index: ${headerStyles.zIndex}; /* $z-index-modal - 1 */
  font-weight: 500;
`;

const sectionStyles = css`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.header?.fontSize};
  text-transform: ${({ theme }) => theme.header?.textTransform};

  @media ${Device.mobile} {
    > .header-location {
      display: none;
    }
  }
`;

const StartSection = styled.div`
  ${sectionStyles};
  overflow: visible;
`;

const EndSection = styled.div`
  ${sectionStyles};
  justify-content: flex-end;
  min-width: 10%;
`;

export const NavHeader: React.FunctionComponent<HeaderProps> = ({
  promotion,
  logo,
  navBar,
  user,
  location,
  shoppingCart,
  subNav,
  customButtonText,
  customButtonLink,
}) => {
  return (
    <StyledHeader className="header">
      {promotion.shouldShow && <HeaderPromo {...promotion} />}
      <HeaderWrapper {...promotion}>
        <StartSection>
          <HeaderNavBarMobile {...navBar} hasSubNavs={!!subNav} />
          <HeaderLogo {...logo} />
          <HeaderNavBar {...navBar} />
        </StartSection>
        <EndSection className="header-end-section">
          <HeaderLocation {...location} />
          {customButtonText && customButtonLink && !navBar.user.isLoggedIn && (
            <div>
              <Button type="primary" internalLink={customButtonLink}>
                {customButtonText}
              </Button>
            </div>
          )}
          <HeaderUser {...user} />
          <HeaderCart {...shoppingCart} />
        </EndSection>
      </HeaderWrapper>
      {!!subNav?.length && <HeaderSubNav subNav={subNav} />}
    </StyledHeader>
  );
};
