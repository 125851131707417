import React from "react";
import styled from "styled-components";
import { HeaderPromoProps } from "../types/header";
import { headerStyles } from "../common/constants";

const Promo = styled.div`
  background-color: ${({ theme }) => theme.color.accent};
  color: ${({ theme }) => theme.color.primary};
  font-size: 14px;
  line-height: ${headerStyles.heightPromo};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${headerStyles.heightPromo};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: ${headerStyles.zIndex}; /* We need this for IE */
`;

export const HeaderPromo: React.FunctionComponent<HeaderPromoProps> = ({
  promotionText,
}) => <Promo data-testid="ui-header-promo">{promotionText}</Promo>;
