import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { ExclamationIconSvg } from "./ExclamationIconSvg";
import { ThemeInterface } from "../../theme/theme";
import { colors } from "../../styles/variables";

const getColor = (theme: ThemeInterface, type?: AlertType) => {
  switch (type) {
    case "error":
      return theme.color.error;
    case "info":
      return theme.alert?.info?.textColor || colors.darkGray;
    default:
      return colors.darkGray;
  }
};

const StyledAlert = styled.div<AlertProps>`
  display: flex;
  padding: 20px;
  border-radius: ${({ theme }) => theme.borderRadius || "4px"};
  border: ${({ type, theme }) =>
    type === "error" && `1px solid ${theme.color.error}`};
  color: ${({ type, theme }) => getColor(theme, type)};
  background-color: ${({ theme, type }) => {
    if (theme.page?.backgroundColor) return "white";
    if (type === "info") return colors.lightGray;
    if (type === "warning") return colors.yellow;
  }};
  font-weight: ${({ type }) => type === "warning" && "bold"};

  svg {
    flex-shrink: 0;
    ${({ type }) =>
      type === "info" &&
      `
      transform: rotate(180deg);
    `}
  }
`;

const Text = styled.span`
  line-height: 1.25;
  margin-left: 10px;
`;

export type AlertType = "info" | "error" | "warning";

interface AlertProps {
  children: ReactNode;
  type?: AlertType;
  className?: string;
}

export const Alert: FC<AlertProps> = ({
  children,
  type = "info",
  className,
}) => {
  return (
    <StyledAlert className={className || ""} type={type} data-testid="alert">
      {type && type !== "warning" && <ExclamationIconSvg type={type} />}
      <Text>{children}</Text>
    </StyledAlert>
  );
};
