import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Device } from "../../../../helpers/screenSizes";
import { HeaderNavBarProps } from "../types/header";
import { MobileMenu } from "./MobileMenu";
import { MobileMenuIcon } from "../../shared/MobileMenuIcon";

const StyledNavMobile = styled.div`
  @media ${Device.laptop} {
    display: none;
  }
  display: flex;
  flex-direction: column;
`;

export const HeaderNavBarMobile: React.FunctionComponent<HeaderNavBarProps> = (
  props
) => {
  const theme = useContext(ThemeContext);
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <StyledNavMobile>
      <MobileMenuIcon
        isOpen={isMenuOpen}
        color={theme.header?.shop?.navLinkColor}
        onClick={(isOpen: boolean) => setMenuOpen(isOpen)}
      />
      <MobileMenu
        {...props}
        isOpen={isMenuOpen}
        keepMobileMenuOpen={(isOpen: boolean) => setMenuOpen(isOpen)}
        hasSubNavs={props.hasSubNavs}
      />
    </StyledNavMobile>
  );
};
