import React, { FC, useContext } from "react";
import { AppContext } from "context/app";
import { useGenericOnClickRouter } from "hooks/useGenericOnClickRouter";
import { Footer } from "@pepdirect/ui/footer";
import {
  getFooterBranding,
  getSortedFooterLinks,
} from "@pepdirect/ui/footer/helpers";
import { endpoints } from "config";

export const NavFooter: FC = () => {
  const { tenant } = useContext(AppContext);
  const { route } = useGenericOnClickRouter();

  const branding = getFooterBranding(
    tenant?.brandConfig?.customizations?.contacts || [],
    tenant?.brandConfig?.customizations?.images || []
  );

  const links = getSortedFooterLinks(
    tenant?.brandConfig?.customizations?.links || [],
    endpoints.web
  );

  const FooterData = {
    branding,
    copyright: { brandName: tenant?.brandConfig?.copyright || "" },
    navigation: [
      {
        onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => {
          route(e);
        },
        links,
      },
    ],
  };

  return <Footer {...FooterData} />;
};
