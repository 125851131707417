import React from "react";
import styled from "styled-components";
import { InternalLink } from "../../../link/InternalLink";
import { Device } from "../../../../helpers/screenSizes";
import { HeaderSubNavProps } from "../types/header";

const StyledLink = styled.div`
  display: inline-block;
  text-align: center;
  padding: 10px;
`;

const StyledSubNav = styled.div`
  background-color: ${({ theme }) => theme.color.accent};
  width: 100vw;
  height: 50px;
  top: 60px;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media ${Device.notLaptop} {
    justify-content: flex-start;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderSubNav: React.FunctionComponent<HeaderSubNavProps> = ({
  subNav,
}) => {
  return (
    <StyledSubNav className="ui-header-sub-nav" data-testid="ui-header-sub-nav">
      {subNav.map((item, index) => (
        <StyledLink key={`${index}-${item.display}`}>
          <InternalLink href={item.url} color="#fff">
            {item.display}
          </InternalLink>
        </StyledLink>
      ))}
    </StyledSubNav>
  );
};
